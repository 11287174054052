export const ELEMENT_DATA_ATTRIBUTE = 'data-el-id';

const createDomElement = ({
	type,
	tagName,
	properties = {},
}) => {
	if (type !== 'element') {
		return console.error('Failed to injected HTML element - missing node type');
	}

	const element = document.createElement(tagName);

	Object.entries(properties).forEach(([property, value]) => {
		if (property === 'innerHTML') {
			element.innerHTML = value;

			return;
		}

		element.setAttribute(property, value);
	});

	return element;
};

/**
 * Removes duplicate element if one is found
 * @param {string} elementDataAttributeValue unique index to differentiate injected element
 */
const removeDuplicateElement = (elementDataAttributeValue) => {
	const elementSelector = `[${ELEMENT_DATA_ATTRIBUTE}="${elementDataAttributeValue}"]`;

	document.querySelector(elementSelector)?.remove();
};

/**
 * @param {object} node Object with element properties that follow hast.
 * @param {string} node.type 'element'
 * @param {string} node.tagName - HTML element tag i.e. 'script', 'link', 'meta'
 * @param {object} node.properties - element attributes in key-value pairs. Must include unique [ELEMENT_DATA_ATTRIBUTE]
 * @description Injects element to head
 */
export const addElementToHead = (node) => {
	const dataAttrValue = node.properties[ELEMENT_DATA_ATTRIBUTE];

	removeDuplicateElement(dataAttrValue);

	const element = createDomElement(node);

	document.head.prepend(element);

	return element;
};

/**
 * @param {object} node Object with element properties that follow hast.
 * @param {string} node.type 'element'
 * @param {string} node.tagName - HTML element tag i.e. 'script', 'link', 'meta'
 * @param {object} node.properties - element attributes in key-value pairs. Must include unique [ELEMENT_DATA_ATTRIBUTE]
 * @description Injects element to body
 */
export const addElementToBody = (node) => {
	const dataAttrValue = node.properties[ELEMENT_DATA_ATTRIBUTE];

	removeDuplicateElement(dataAttrValue);

	const element = createDomElement(node);

	document.body.append(element);

	return element;
};
